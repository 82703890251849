import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconArrowUp = props => (
<svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 9 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="idIconArrowUp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1360.000000, -231.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
            <g id="stats" transform="translate(30.000000, 152.000000)">
                <g id="table" transform="translate(0.000000, 15.000000)">
                    <g id="table-header" transform="translate(0.000000, 45.000000)">
                        <g id="icons/arrow-up-active" transform="translate(1330.000000, 19.000000)">
                            <path d="M0.299402787,3.48135368 L3.64379562,0.286662243 C3.82853351,0.095554081 4.08015926,0 4.35726609,0 C4.63437293,0 4.88281354,0.095554081 5.07073656,0.286662243 L8.42786994,3.48135368 C8.82601194,3.86038487 8.82601194,4.47830126 8.42786994,4.85733245 C8.02972794,5.23636364 7.38633046,5.23636364 6.98818845,4.85733245 L5.38287989,3.32528202 L5.38287989,10.2370272 C5.38287989,10.7753152 4.92740544,11.2116788 4.36363636,11.2116788 C3.79986729,11.2116788 3.34439283,10.7753152 3.34439283,10.2370272 L3.34439283,3.32528202 L1.73908427,4.86051758 C1.34094227,5.23954877 0.697544791,5.23954877 0.299402787,4.86051758 C-0.098739217,4.47830126 -0.098739217,3.86357001 0.299402787,3.48135368 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

IconArrowUp.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number
};

IconArrowUp.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16
};

export default IconArrowUp;