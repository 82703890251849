import React, { Component } from 'react';
import GridRow from '../../master-components/Grid/GridRow';
import GridCol from '../../master-components/Grid/GridCol';
import InputLabel from '../../master-components/InputLabel/InputLabel';
import GridHeader from '../../master-components/GridHeader/GridHeader';
import Button from '../../master-components/Button/Button';
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import DistributorAPI from './DistributorAPI'

class DistributorContent extends Component {

    constructor(props){
        super(props);
        this.state = {
            distributorsFromAPI: [],
        }
    }

    componentDidMount = () =>{
      const merchantId = this.props.match.params.merchantId;
      this.getDistributors(merchantId);
    }

    componentWillReceiveProps = (nextProps) => {
      const merchantId = this.props.match.params.merchantId;
      this.getDistributors(merchantId);
    }

    getDistributors = (id) => {
      DistributorAPI.getDistributors(id).then((res => {
            const distributors = res.data.data;
            this.setState({distributorsFromAPI : distributors });
        }));
    }

    resetDistributor = (id) => {
        const dispatch = this.props.dispatch;

        dispatch(spinner(true));
        DistributorAPI.resetDistributor(id)
            .then(({data}) => {
                dispatch(spinner(false));
            }).catch(error => {
                dispatch(spinner(false));
                if (error.status) {
                    let errorMessage = error.data.data.message;
                    alert(errorMessage);
                }
            });
    }

    render() {
        const distributorHeaderFields = [
            { name: 'Distributor ID',  className : 'col-2' },
            { name: 'Distributor Name',  className : 'col-3' },
            { name: 'Data Capture Type',  className : 'col-2' },
            { name: 'DCN',  className : 'col-2' },
            { name: 'DAN',  className : 'col-2' },
            { name: '',  className : 'col-1' },
          ];

          const distributorHeader = distributorHeaderFields.map((c, i) => (
            <GridHeader {...c} key={i} />
          ));

          const distributorDetails = this.state.distributorsFromAPI.map((c, i) => (
           <div className='grid-text-detail grid-row-detail  d-flex grid-bottom-border clickable pl-3' onClick={this.goToDetailsMerchant} key={i} >
              <GridCol className='col align-self-center col-2'>{c.distributorId}</GridCol>
              <GridCol className='col align-self-center col-3'>{c.distributorName}</GridCol>
              <GridCol className='col align-self-center col-2 text-capitalize'>{c.submissionType}</GridCol>
              <GridCol className='col align-self-center col-2 text-capitalize'>{c.dcn}</GridCol>
              <GridCol className='col align-self-center col-2 text-capitalize'>{c.dan}</GridCol>
              <GridCol className='col align-self-center col-1 text-capitalize'>
                <Button onClick={() => this.resetDistributor(c.id)} className='w-100 btn-rb-yellow clickable' size='sm' text='Reset' />
              </GridCol>
            </div>
          ));

        return (
            <div className='container-fluid p-0 mt-5'>
                <InputLabel className='pl-4'>Distributor ({this.state.distributorsFromAPI && this.state.distributorsFromAPI.length})</InputLabel>
                <GridRow className='m-0'>{distributorHeader}</GridRow>
                {distributorDetails}
            </div>
        );
    }
}

export default DistributorContent;
