import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../master-components/Modal/Modal';
import ModalBody from '../../../master-components/Modal/ModalBody';
import ModalHeader from '../../../master-components/Modal/ModalHeader';
import GridRow from '../../../master-components/Grid/GridRow';
import InputLabel from '../../../master-components/InputLabel/InputLabel';
import Input from '../../../master-components/Input/Input';
import Button from '../../../master-components/Button/Button';
import CheckBox from '../../../master-components/CheckBox/CheckBox';
import { spinner } from '../../../master-components/Spinner/SpinnerActions';
import { findModal } from '../../../master-components/Modal/ModalActions';
import UserAPI from '../UserAPI'
import GridCol from '../../../master-components/Grid/GridCol';

class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id : props.user.id,
            contactName : props.user.contactName,
            email : props.user.email,
            password : '',
            softDelete : props.user.softDelete,
            allLocations : props.user.allLocations,
            displayMoxe : props.user.displayMoxe,
            isActivated : props.user.isActivated && props.user.status === 10,
            isActivatedPassword : false
        };
    }

    changeHandlerUser = (value, name) => {
        this.setState({
            [name]: value
        });
    }

    checkActive = () => {
        this.setState({
            isActivated : this.state.isActivated === 1 ? 0 : 1
        });
    }

    checkSoftDelete = () => {
        this.setState({
            softDelete : this.state.softDelete === 1 ? 0 : 1
        });
    }

    checkAllLocations = () => {
        this.setState({
            allLocations : this.state.allLocations === 1 ? 0 : 1
        });
    }

    checkDisplayMoxe = () => {
        this.setState({
            displayMoxe : this.state.displayMoxe === 1 ? 0 : 1
        });
    }

    checkActivePassword = () => {
        this.setState({
            isActivatedPassword : this.state.isActivatedPassword === true ? false : true
        });
    }

    saveUser = (e) => {
        e.preventDefault();
        const {modals} = this.props;
        const {id, allLocations, displayMoxe, isActivatedPassword, isActivated } = this.state;
        const params = {
           ...this.state,
           allLocations: allLocations ? 1 : 0,
           displayMoxe: displayMoxe ? 1 : 0,
           isActivated: isActivated ? 1 : 0,
        }
        const { nameInput, emailInput, passwordInput } = this.refs;
        if(isActivatedPassword) {
            if(nameInput.isValid() && emailInput.isValid() && passwordInput.isValid()){
                this.updateUserFunc(id, params, modals);
                this.resetPassword(id, {'password': this.state.password })
            }
        } else {
            if(nameInput.isValid() && emailInput.isValid()){
                this.updateUserFunc(id, params, modals);
            }
        }
    }

    updateUserFunc = (id, params, modals) => {
        const dispatch = this.props.dispatch;
        dispatch(spinner(true));
        UserAPI.updateUser(id, params)
        .then(({data}) => {
            dispatch(spinner(false));
            this.props.updateGridUser();
            findModal(modals, 'idUserEdit').close();
        }).catch(error => {
            if (error.status) {
                let errorMessage = error.data.data.message;
                alert(errorMessage);
            }
            findModal(modals, 'idUserEdit').close();
            dispatch(spinner(false));
        });
    }

    resetPassword = (id, params) => {
        UserAPI.resetUserPassword(id, params)
        .then(({data}) => {
        }).catch(error => {
            if (error.status) {
                let errorMessage = error.data.data.message;
                alert(errorMessage);
            }
        });
    }

    render() {
        const {contactName, email, password, softDelete, allLocations, displayMoxe, isActivated, isActivatedPassword} = this.state;
        return (
          <Modal id='idUserEdit'>
                <ModalHeader>Edit User</ModalHeader>
                <ModalBody className='text-center text-size-14 text-line-height-20'>
                    <form autoComplete='off' onSubmit={this.saveUser}>
                        <GridRow>
                            <InputLabel className='grid-text-header'>User Name</InputLabel>
                            <Input ref='nameInput' value={contactName}  name='contactName' type='text'  onChange={this.changeHandlerUser} autocomplete='off' validate min={5} max={255} />
                        </GridRow>
                        <GridRow className='mt-4'>
                            <InputLabel className='grid-text-header'>User Email</InputLabel>
                            <Input ref='emailInput' value={email} name='email' onChange={this.changeHandlerUser} type='email' autocomplete='off' validate min={5} max={255}  />
                        </GridRow>
                        <GridRow className='mt-4'>
                            <GridCol className='col-11 pl-0 text-left'>
                                <InputLabel className='grid-text-header'>Password</InputLabel>
                                { isActivatedPassword ?
                                    <Input  ref='passwordInput' value={password} name='password' onChange={this.changeHandlerUser} type='password' validate min={5} max={25}  />
                                    : <Input  ref='passwordInput' value={password} name='password' onChange={this.changeHandlerUser} type='text' disabled  />
                                }

                            </GridCol>
                            <GridCol className='col-1'>
                                <InputLabel/>
                                <CheckBox name={isActivatedPassword} value={isActivatedPassword} text='' onChange={this.checkActivePassword} />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol className='col-4 pl-0 text-left'>
                            <CheckBox name={isActivated} value={isActivated} text='Active' onChange={this.checkActive} />
                            </GridCol>
                            <GridCol className='col-4 pl-0 text-left'>
                            <CheckBox name={softDelete} value={softDelete} text='Soft Delete' onChange={this.checkSoftDelete} />
                            </GridCol>
                            <GridCol className='col-4 pl-0 text-left'>
                            <CheckBox name={allLocations} value={allLocations} text='All Locations' onChange={this.checkAllLocations} />
                            </GridCol>
                            <GridCol className='col-4 pl-0 text-left'>
                            <CheckBox name={displayMoxe} value={displayMoxe} text='Display Moxe' onChange={this.checkDisplayMoxe} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='mt-4'>
                            <Button type='submit' className='btn-rb-green clickable' text='save'  />
                        </GridRow>
                    </form>
                </ModalBody>
            </Modal>
        );
    }
}

export default connect()(UserEdit);
