import { Component } from "react";

class Logout extends Component {
    componentDidMount() {
        localStorage.clear();
        // removeToken();
        const instance = this.props.msalContext.instance;
        instance.logoutRedirect({
            account: instance.getAccountByHomeId(),
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/'
        });
        this.props.history.push('/');
    };
    render() {
        return null;
    };
};

export default Logout;
