import React, { Component } from 'react';
import DistributorContent from './DistributorContent';

class Distributor extends Component {
    render() {
        const props = this.props;
        return (
            <DistributorContent {...props}/>
        );
    }
}

export default Distributor;