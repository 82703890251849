import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import  Login from '../views/Login/Login'
import  Dashboard from '../views/Dashboard/Dashboard'
import MerchantDetails from '../views/Merchant/MerchantDetails';
// import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import Logout from '../views/Logout/Logout';
import { withMsal } from '@azure/msal-react';

class Router extends Component {
    render() {
        // const isAuthenticated = this.props.msalContext.accounts.length > 0;
        // if (isAuthenticated) {
        if (localStorage.getItem('authKey')) {
            return (
                <div>
                    <Switch>
                        <Route exact path='/dashboard' component={Dashboard} />
                        <Route exact path='/dashboard/details/:merchantId' component={MerchantDetails} />
                        <Route exact path='/logout' component={Logout} />
                        <Route>
                            <Redirect to='/dashboard' />
                        </Route>
                    </Switch>
                </div>
            );
        } else {
            return (
                <div className="App">
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route exact path='/login' />
                            <Redirect to='/' />
                        {/* <Route exact path='/forgot-password' component={ForgotPassword} /> */}
                        <Route>
                            <Redirect to='/' />
                        </Route>
                    </Switch>
                </div>
            );
        }
    }
}

export default withMsal(Router);
