export default function reducer(state = {
    showBack: false,
    title: 'Partner Dashboard',
    search: '',
}, action) {
    switch (action.type) {
        case 'SHOW_BACK':
            return { ...state, showBack: action.payload };
        case 'SET_TITLE':
            return { ...state, title: action.payload };
        case 'SET_SEARCH':
            return { ...state, search: action.payload };
        default:
            return state;
    }
}
