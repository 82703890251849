import axios from 'axios';


const getMerchants = () => {
    return axios.get('/merchant/all');
}

const getMerchantsAll = (params) => {
    return axios.get(`/merchant/all?page=${params}`);
}

const getMerchantsByFieldName = (params) => {
    var filter = JSON.stringify(params.filterOptions)
    return axios.get(`/merchant/all-sort?search=${params.search}&filterOptions=${filter}&page=${params.page}`);
}

const getMerchantById = (id) => {
    return axios.get(`/merchant/${id}`);
}

const updateMerchant = (id,params) => {
    return axios.patch(`/merchant/${id}`, params);
};

const importMerchants = (file) => {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post('/merchant/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
};

const syncMechantLocations = (id) => {
    return axios.post(`/merchant/${id}/sync-locations`);
};

const syncMechantDistributors = (id) => {
    return axios.post(`/merchant/${id}/sync-distributors`);
};

const dcnCreate = (id) => {
    return axios.post(`/merchant-distributor/${id}/dcn`);
};

const getAllChannelPartners = () => {
    return axios.get('/referred-partner');
}

export default {
    getMerchants,
    getMerchantsAll,
    getMerchantById,
    getMerchantsByFieldName,
    updateMerchant,
    importMerchants,
    syncMechantLocations,
    syncMechantDistributors,
    dcnCreate,
    getAllChannelPartners,
}
