import React, { Component } from 'react';
import ParentContent from './ParentContent';

class Parent extends Component {
    render() {
        const props = this.props;
        return (
           <ParentContent  {...props} />
        );
    }
}

export default Parent;