import React, {Component} from 'react';
import './App.scss';
import Router from '../App/Router';
import Spinner from '../master-components/Spinner/Spinner';
import ModalContent from '../master-components/Modal/ModalContent';
import ResizeListener from '../master-components/ResizeListener/ResizeListener';

class App extends Component {
  render() {
        return (
          <div className="App">
            <Router />
            <ModalContent />
            <ResizeListener />
            <Spinner />
          </div>
        );
  }
}


export default App;
