import React from 'react';
import './Button.scss';
import PropTypes from 'prop-types';

const Button = props => (
    <button
        type={props.type}
        className={((props.size) === '' ? 'btn-bs' : 'btn-' + props.size) + ' btn-rb-' + props.variant + (props.className ? ` ${props.className}` : '')}
        disabled={ props.disabled }
        onClick={props.onClick}
        style={{...props.style}}
    >
        {props.text}
    </button>
);

Button.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

Button.defaultProps = {
    size: '',
    variant: '',
    disabled: false
};

export default Button;
