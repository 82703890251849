import React, { Component, Fragment } from 'react';
import GridCol from "../../master-components/Grid/GridCol";

class GridHeader extends Component {
    render() {
        const props = this.props;
        return (
           <Fragment >
                <GridCol className={'grid-row-header grid-text-header grid-top-border grid-bottom-border d-flex align-items-center pl-4'+ (props.className ? ` ${props.className}` :'')}>
                    {props.name}
                </GridCol>
      </Fragment>
        );
    }
}

export default GridHeader;