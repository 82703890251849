export default function reducer(state = {
    modals: [],
    canGoBack: false
  }, action) {
    switch (action.type) {
      case 'ADD_MODAL':
        return {
          ...state,
          modals: [...state.modals, action.payload]
        };
      case 'SAVE_PARENT':
        return {
          ...state,
          modals: state.modals.map((m) => {
            if (m.id !== action.payload.id) {
              return m;
            }
            return {
              ...m,
              parent: action.payload.parent
            }
          })
        };
      case 'REMOVE_MODAL':
        return {
          ...state,
          modals: state.modals.filter(item => item.id !== action.payload)
        };
      case 'CAN_GO_BACK':
        return {
          ...state,
          canGoBack: action.payload
        }
      default:
        return state;
    }
  }