import axios from 'axios';


const getDistributors = (merchantId) => {
    return axios.get(`/merchant/${merchantId}/distributors`);
}

const resetDistributor = (id) => {
    return axios.patch(`/merchant-distributor/${id}/reset`);
}

export default {
    getDistributors,
    resetDistributor,
}
