import React, { Component } from 'react';
import SiblingContent from './SiblingContent';

class Sibling extends Component {
    render() {
        const props = this.props;
        return (
            <SiblingContent {...props} />
        );
    }
}

export default Sibling;