import React from 'react';
import PropTypes from 'prop-types';

const GridRow = props => (
  <div style={{minHeight: props.minHeight, minWidth: props.minWidth, ...props.style}} id={props.id} className={(props.className ? 'row '+ props.className : 'row') + (props.align ? ' align-items-' + props.align : '') + (props.justify ? ' justify-content-' + props.justify : '')} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} onClick={props.onClick} tabIndex={props.tabIndex} onBlur={props.onBlur} onFocus={props.onFocus}>
    {props.children}
  </div>
);

GridRow.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  align: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  justify: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default GridRow;