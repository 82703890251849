import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './Merchant.scss';
import MerchantAPI from './MerchantAPI';
import GridRow from '../../master-components/Grid/GridRow';
import GridCol from '../../master-components/Grid/GridCol';
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import {setHeaderTitle, showHeaderBack, setSearch } from '../Dashboard/DashboardActions';
import {setDataMerchant} from '../Merchant/MerchantActions';
import InfiniteScroll from 'react-infinite-scroller';
import Input from '../../master-components/Input/Input';
import IconSearch from '../../master-components/Icons/IconSearch';
import IconArrowDown from '../../master-components/Icons/IconArrowDown';
import IconArrowUp from '../../master-components/Icons/IconArrowUp';
import IconClose from '../../master-components/Icons/IconClose';
import config from '../../config/config';

class MerchantContent extends Component {

    constructor(props){
        super(props);
        this.initialLoad = true;
        this.state = {
            merchantFromAPI: [],
            arrayMerchant : [],
            hasMore : true,
            initialLoad: true,
            isLoading : false,
            search : props.search,
            isActive: [false, true, false, false, false, false, false],
            isSortAsc: [true, true, true, true, true, true, true],
            isCurrentField : 'nickname',
            sort : 'asc',
            pageNumber : 1
        }
    }

    componentDidMount = () => {
        const dispatch = this.props.dispatch;
        dispatch(setHeaderTitle('Merchant Detalle'));
        dispatch(showHeaderBack(false));
        const merchant = localStorage.getItem('dataMerchant');
        if(merchant === null){
            this.searchByFilter();
        } else {
            this.setState({
                merchantFromAPI : JSON.parse(merchant),
                arrayMerchant : JSON.parse(merchant)
            });
        }
        this.searchByFilter();
    }

    scrollTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }

    onChangeSearch = (value, name) => {
        const dispatch = this.props.dispatch;
        this.setState({
            [name]: value
        });
        if (name === 'search') {
            dispatch(setSearch(value));
        }
    }

    onKeyPressSearch = (e) => {
        const {search} = this.state;
        if(e.key === 'Enter' && search !== ''){
            this.searchByFilter();
        }
    }

    onClickSearch = () => {
        const {search} = this.state;
        if(search !== ''){
            this.searchByFilter();
        }
    }

    getMerchant = () => {
        const dispatch = this.props.dispatch;
        dispatch(spinner(true));
        MerchantAPI.getMerchantsAll(1).then((res => {
            dispatch(spinner(false));
            const merchants = res.data.data;
            this.setState({
                merchantFromAPI: [...this.state.merchantFromAPI, ...merchants ],
                arrayMerchant: [...this.state.arrayMerchant, ...merchants ]
            });
            localStorage.setItem('dataMerchant', JSON.stringify(merchants));
        }));
    }

    clearSearchName = () => {
        this.setState({
            search : '',
            arrayMerchant : [],
            isActive: [false, true, false, false, false, false, false],
            isSortAsc: [true, true, true, true, true, true, true],
            isCurrentField : 'nickname',
            sort : 'asc'
        });
        this.getMerchant();
    }

    goToDetailsMerchant = (id) => {
        const dispatch = this.props.dispatch;
        const arrayMechantDetail = this.state.merchantFromAPI.find(item => item.id === id)
        dispatch(setDataMerchant(arrayMechantDetail));
        this.props.history.push('/dashboard/details/'+ id);
    }

    loadItems = () => {
        const dispatch = this.props.dispatch;
        const {search} = this.state;
        this.setState({ isLoading: true });
        let paramObj = {filterOptions: {}};
        paramObj.search = search;
        paramObj.page = this.state.pageNumber;
        paramObj.filterOptions.field = this.state.isCurrentField;
        paramObj.filterOptions.sort = this.state.sort;
        dispatch(spinner(true));
        MerchantAPI.getMerchantsByFieldName(paramObj).then((res => {
            dispatch(spinner(false));
            const merchants = res.data.data;
            if(this.state.pageNumber < res.data._meta.pageCount) {
                this.setState({
                    hasMore : true,
                    isLoading : false,
                    pageNumber : this.state.pageNumber + 1,
                    merchantFromAPI: [...this.state.merchantFromAPI, ...merchants ],
                    arrayMerchant: [...this.state.arrayMerchant, ...merchants ]
                });
            } else {
                this.setState({
                    hasMore : false,
                    isLoading : false,
                    pageNumber : 1
                });
            }
        }))
        .catch(error => {
            dispatch(spinner(false));
            console.error('Error during service worker registration:', error);
        });
    }

    searchByFilter = (field, i) => {
         const dispatch = this.props.dispatch;
         const {search} = this.state
         let paramObj = {filterOptions: {}};
         let sortValue = this.state.isSortAsc[i];
         paramObj.search = search;
         paramObj.page = 1;
         if(field === undefined){
            paramObj.filterOptions.field = 'nickname';
            this.setState({ isCurrentField : 'nickname'});
         } else {
            paramObj.filterOptions.field = field;
            this.setState({ isCurrentField : field });
         }
        if(!this.state.isActive[i]) {
            paramObj.filterOptions.sort = 'asc';
            this.setState({ sort : 'asc', pageNumber : 2, hasMore : true});
        } else {
            if(sortValue) {
                paramObj.filterOptions.sort = 'desc';
                this.setState({ sort : 'desc', pageNumber : 2});
            } else {
                paramObj.filterOptions.sort = 'asc';
                this.setState({ sort : 'asc', pageNumber : 2});
            }
        }
         dispatch(spinner(true));
         MerchantAPI.getMerchantsByFieldName(paramObj).then((res => {
             dispatch(spinner(false));
             const merchants = res.data.data;
             this.setState({
                 merchantFromAPI: merchants,
                 arrayMerchant: merchants
             });
             this.scrollTop();
             switch(field) {
                case 'id' :
                        if(!this.state.isActive[i]) {
                            this.setState({isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({isSortAsc : [!sortValue, true, true, true, true, true, true]});
                        }
                        this.setState({isActive : [true, false, false, false, false, false, false]});
                        break;
                case 'nickname' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({isSortAsc : [true, !sortValue, true, true, true, true, true]});
                        }
                        this.setState({isActive : [false, true, false, false, false, false, false]});
                        break;
                case 'createTime' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({ isSortAsc : [true, true, !sortValue, true, true, true, true]});
                        }
                        this.setState({isActive : [false, false, true, false, false, false, false]});
                        break;
                case 'lastLoaded' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({ isSortAsc : [true, true, true, !sortValue, true, true, true]});
                        }
                        this.setState({isActive : [false, false, false, true, false, false, false]});
                        break;
                case 'lastLoggedIn' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({ isSortAsc : [true, true, true, true, !sortValue, true, true]});
                        }
                        this.setState({isActive : [false, false, false, false, true, false, false]});
                        break;
                case 'platformId' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({ isSortAsc : [true, true, true, true, true, !sortValue, true]});
                        }
                        this.setState({isActive : [false, false, false, false, false, true, false]});
                        break;
                case 'sfClientHoldingId' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({ isSortAsc : [true, true, true, true, true, true, !sortValue]});
                        }
                        this.setState({isActive : [false, false, false, false, false, false, true]});
                        break;
                case 'channelPartner' :
                        if(!this.state.isActive[i]) {
                            this.setState({ isSortAsc: [true, true, true, true, true, true, true]});
                        } else {
                            this.setState({ isSortAsc : [true, true, true, true, true, true, !sortValue]});
                        }
                        this.setState({isActive : [false, false, false, false, false, false, true]});
                        break;
                default :
                break;
            }
         }))
         .catch(error => {
             dispatch(spinner(false));
             console.error('Error during service worker registration:', error);
         });
     }

    render() {
        const {search} = this.state;
        const merchantHeaderFields = [
          {
            name: 'Merchant ID',
            field: 'id',
            className: 'col-1 clickable',
            arrow: this.state.isSortAsc[0] ? <i style={{ position: 'absolute', top: '20px', left: '95px' }}><IconArrowDown color={this.state.isActive[0] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'absolute', top: '20px', left: '95px' }}><IconArrowUp color={this.state.isActive[0] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          { name: 'Merchant Name',
            field: 'nickname',
            className: 'col-2 clickable' ,
            arrow: this.state.isSortAsc[1] ? <i style={{ position: 'absolute', top: '20px', left: '120px' }}><IconArrowDown color={this.state.isActive[1] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'absolute', top: '20px', left: '120px' }}><IconArrowUp color={this.state.isActive[1] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          { name: 'Created On',
            field: 'createTime',
            className: 'col-1 clickable',
            arrow: this.state.isSortAsc[2] ? <i style={{ position: 'absolute', top: '20px', left: '90px' }}><IconArrowDown color={this.state.isActive[2] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'absolute', top: '20px', left: '90px' }}><IconArrowUp color={this.state.isActive[2] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          { name: 'Last Loaded',
            field: 'lastLoaded',
            className: 'col-1 clickable',
            arrow: this.state.isSortAsc[3] ? <i style={{ position: 'relative', top: '0px', left: '0' }}><IconArrowDown color={this.state.isActive[3] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'relative', top: '0px', left: '0' }}><IconArrowUp color={this.state.isActive[3] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          { name: 'Last Logged In',
            field: 'lastLoggedIn',
            className: 'col-1 clickable',
            arrow: this.state.isSortAsc[4] ? <i style={{ position: 'absolute', top: '20px', left: '95px' }}><IconArrowDown color={this.state.isActive[4] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'absolute', top: '20px', left: '95px' }}><IconArrowUp color={this.state.isActive[4] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          { name: 'Platform ID',
            field: 'platformId',
            className: 'col-2 clickable',
            arrow: this.state.isSortAsc[5] ? <i style={{ position: 'absolute', top: '20px', left: '90px' }}><IconArrowDown color={this.state.isActive[5] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'absolute', top: '20px', left: '90px' }}><IconArrowUp color={this.state.isActive[5] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          {
            name: 'Channel Partner',
            field: 'channelPartner',
            className: 'col-2 clickable',
            arrow: this.state.isSortAsc[6] ? <i style={{ position: 'absolute', top: '20px', left: '140px' }}><IconArrowDown color={this.state.isActive[6] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i> : <i style={{ position: 'absolute', top: '20px', left: '140px' }}><IconArrowUp color={this.state.isActive[6] ? config.colors.rbDarkBlueOpaque : config.colors.rbLighterAqua} size={16} /></i>
          },
          {
            name: (
              <div>
                <span style={{ position: 'relative', top: '10px' }}>
                    <Input
                        className="search"
                        placeholder="Search..."
                        value={search}
                        name="search"
                        type="text"
                        onChange={this.onChangeSearch}
                        onKeyPress={this.onKeyPressSearch}
                    />
                </span>{" "}
                <span
                  className="clickable"
                  onClick={this.onClickSearch}
                  style={{ position: 'relative', top: '-20px', left: '8px' }}
                >
                  <IconSearch color={config.colors.rbDarkAqua} size={16} />
                </span>{" "}
                <span
                  className="clickable"
                  onClick={this.clearSearchName}
                  style={{ position: 'relative', top: '-20px', left: '145px' }}
                >
                  <IconClose color={config.colors.rbDarkAqua} size={11} />
                </span>{" "}
              </div>
            ),
            field : 'searchByName'
          }
        ];


        const merchantHeader = merchantHeaderFields.map((c, i) => (
            <Fragment key={i}>
                <GridCol className={'grid-text-header  d-flex align-items-center  grid-bottom-border' + (c.className ? ` ${c.className}` :'')} onClick={ c.field !== 'searchByName' ? this.searchByFilter.bind(this, c.field, i) : null} style= {this.state.isActive[i] ? { color: '#143357', fontSize:'13px' } : { color: '#A6B7C4', fontSize:'13px'}} >
                    {c.name} {c.arrow}
                </GridCol>
            </Fragment>
        ));

        const merchanDetails = this.state.arrayMerchant.map((c, i) => (
            <GridRow key={i} className='grid-text-detail grid-row-detail d-flex grid-bottom-border'>
                <GridCol className='align-self-center col-1'>{c.id}</GridCol>
                <GridCol className='align-self-center col-2 clickable' onClick={this.goToDetailsMerchant.bind(this, c.id)}>{c.nickname}</GridCol>
                <GridCol className='align-self-center col-1'>{c.createTime !== null ? moment(c.createTime).format('MM-DD-YY') : c.createTime }</GridCol>
                <GridCol className='align-self-center col-1'>{c.lastLoaded !== null ? moment(c.lastLoaded).format('MM-DD-YY') : c.lastLoaded}</GridCol>
                <GridCol className='align-self-center col-1'>{c.lastLoggedIn !== null ? moment(c.lastLoggedIn).format('MM-DD-YY') : c.lastLoggedIn}</GridCol>
                <GridCol className='align-self-center col-2'>{c.platformId}</GridCol>
                <GridCol className='align-self-center col-2'>{c.channelPartner}</GridCol>
                <GridCol></GridCol>
             </GridRow>
        ));

        return (
            <div className='container-fluid'>
                <InfiniteScroll
                    initialLoad = {false}
                    pageStart={this.state.pageNumber}
                    loadMore={this.loadItems}
                    hasMore={this.state.hasMore && !this.state.isLoading}
                    useWindow={true}
                    >
                    <GridRow className='merchant-row-fixed'>
                        {merchantHeader}
                    </GridRow>
                    {merchanDetails}
                </InfiniteScroll>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        search: state.dashboard.search,
    };
}

export default connect(mapStateToProps)(MerchantContent);
