import axios from 'axios';

const authenticateAdminPortal = (email,password) => {
    return axios.post('/auth/admin-portal', {
            username: email,
            password: password
    });
};

const authenticateMicrosoft = (payload) => {
    return axios.post('/auth/microsoft', payload);
};

export default {
    authenticateAdminPortal,
    authenticateMicrosoft
}
