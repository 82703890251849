import React, { Component } from 'react';
import PropTypes from 'prop-types'; 
import './Input.scss';
import { isEmailValid, isTextValid, isNumberValid, isPhoneValid, filterPhone, isZipCode } from './InputValidate';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      preValue: this.props.value
    }
  };
  onFocus = (e) => {
    const { validate, onFocus } = this.props;
    if (validate) {
      this.setState({
        isValid: true
      });
    }
    if (onFocus) {
      onFocus(e);
    }
  }
  setInvalid = () => {
    this.setState({
      isValid: false
    });
  }
  setValid = () => {
    this.setState({
      isValid: true
    });
  }
  isValid = () => {
    const { value, type, min, max } = this.props;
    let v = true;
    switch (type) {
      case 'tel':
        v = isPhoneValid(value, true);
        this.setState({
          isValid: v
        });
        return v;
      case 'email':
        v = isEmailValid(value);
        this.setState({
          isValid: v
        });
        return v;
      case 'text':
      case 'password':
        if (!min || !max) {
          console.error('You are validating for text please add min and max lenght to props.');
        }
        v = isTextValid(value, min, max);
        this.setState({
          isValid: v
        });
        return v;
      case 'number':
        if (!min || !max) {
          console.error('You are validating for number please add min and max to props.');
        }
        v = isNumberValid(value, min, max);
        this.setState({
          isValid: v
        });
        return v;
      case 'zipCode':
        v = isZipCode(value);
        this.setState({
          isValid: v
        });
        return v;
      default:
        return true
    }
  }
  onBlur = (e) => {
    const { validate, value, type, min, max, onBlur } = this.props;
    if (validate) {
      switch (type) {
        case 'tel':
          this.setState({
            isValid: isPhoneValid(value, true)
          });
          break;
        case 'email':
          this.setState({
            isValid: isEmailValid(value)
          });
          break;
        case 'text':
        case 'password':
          if (!min || !max) {
            console.error('You are validating for text please add min and max lenght to props.');
          }
          this.setState({
            isValid: isTextValid(value, min, max)
          });
          break;
        case 'number':
          if (!min || !max) {
            console.error('You are validating for number please add min and max to props.');
          }
          this.setState({
            isValid: isNumberValid(value, min, max)
          });
          break;
        case 'zipCode':
          this.setState({
            isValid: isZipCode(value)
          });
          break;
        default:
          break;
      }
    }
    if (onBlur) {
      onBlur(e);
    }
  }
  onChange = (e) => {
    const { onChange, type, validate, max, name } = this.props;
    const { preValue } = this.state;
    let newValue = e.currentTarget.value;
    if (validate) {
      switch (type) {
        case 'tel':
          newValue = filterPhone(newValue, preValue);
          if (isPhoneValid(newValue)) {
            onChange(newValue, name, e);
          }
          break;
        case 'text':
        case 'password':
          if (!max) {
            console.error('You are validating for text please add min and max lenght to props.');
          }
          if (isTextValid(newValue, 0, max)) {
            onChange(newValue, name, e);
          }
          break;
        default:
          onChange(newValue, name, e);
          break;
      }
    } else {
      if (type === 'tel') {
        newValue = filterPhone(newValue, preValue);
        if (isPhoneValid(newValue)) {
          onChange(newValue, name, e);
        }
      } else {
        onChange(newValue, name, e);
      }
    }
    this.setState({
      preValue: newValue
    });
  }
  render() {
    const { props, state } = this;
    return(
      <input autoComplete={props.autoComplete ? props.autoComplete : "new-password"}
        disabled={this.props.disabled}
        onChange={this.onChange} type={props.type === 'zipCode' ? 'text' : props.type} value={props.value}
        className={"rb-input form-control" + (props.className ? ` ${props.className}` : '') + (props.isInvalid ? ' is-invalid' : '') + (!state.isValid ? ' is-invalid' : '')}
        placeholder={props.placeholder}
        onBlur={this.onBlur} onFocus={this.onFocus}
        onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter}
        onClick={props.onClick}
        onKeyPress = {props.onKeyPress} />
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  validate: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onKeyPress : PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isInvalid: PropTypes.bool,
  preValue: PropTypes.string
}

Input.defaultProps = {
  type: 'text',
}

export default Input;