import React, { Component } from 'react';
import Select from 'react-select';
import './DropDownList.scss';

class DropDownListSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isValid: true
    };
  }
  open = () => {
    this.setState({
      open: true,
      isValid: true
    })
  }
  close = () => {
    this.setState({
      open: false
    })
  }
  isValid = () => {
    if (this.props.isMulti) {
      if (this.props.value.length === 0) {
        this.setState({
          isValid: false
        });
        return false;
      } else {
        this.setState({
          isValid: true
        });
        return true;
      }
    } else if (this.props.value === null) {
      this.setState({
        isValid: false
      });
      return false;
    } else {
      this.setState({
        isValid: true
      });
      return true;
    }
  }
  render() {
    const { className, classNamePrefix, isSearchable, value, ...allProps} = this.props;
    return (
      <Select className={'rb-select' + (className ? ` ${className}` :'') + (!this.state.isValid ? ' is-invalid' : '') + (this.props.isMulti ? ' isMulti' : '' )}
        value={value}
        classNamePrefix='rb-select'
        {...allProps}
        onMenuClose={this.close}
        onMenuOpen={this.open}
        isSearchable={isSearchable}
        placeholder={isSearchable && this.state.open ? 'Type to Search' : 'Select'} />
    );
  }
}

DropDownListSelect.defaultProps = {
  isSearchable: true,
}

export default DropDownListSelect;





