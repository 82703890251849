export const setHeaderTitle = (title) => {
    return {
        type: 'SET_TITLE',
        payload: title
    }
}

export const showHeaderBack = (bool) => {
    return {
        type: 'SHOW_BACK',
        payload: bool
    }
}

export const setSearch = (search) => {
    return {
        type: 'SET_SEARCH',
        payload: search
    }
}

export default setHeaderTitle;
