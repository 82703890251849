import config from "./config";
import axios from 'axios';

const token = 'Bearer ' + localStorage.getItem('authKey');
// const token = 'Bearer BwaY-Kam8VXNUPlQXSw9l1Ql8AjRuK8-';
axios.defaults.baseURL = config.apiURL;
axios.defaults.headers['Pragma'] = 'no-cache';
axios.defaults.headers.common['Authorization'] = token;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        // alert('Please login with proper credentials.');
        // window.location.href = `/#/logout`;
    }
    return Promise.reject(error.response);
});

const setToken= (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export default setToken;
