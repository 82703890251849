import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';

const IconEdit = props => (
 <svg width={props.size + 'px'} height={props.size + 'px'} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2---Merchant-Details" transform="translate(-1361.000000, -755.000000)" fill={!props.isActive ? props.color : props.activeColor} fillRule="nonzero">
            <g id="stats" transform="translate(30.000000, 91.000000)">
                <g id="group" transform="translate(0.000000, 544.000000)">
                    <g id="table" transform="translate(0.000000, 46.000000)">
                        <g id="item" transform="translate(0.000000, 52.000000)">
                            <g id="edit" transform="translate(1323.000000, 14.000000)">
                                <g id="icons/edit" transform="translate(8.000000, 8.000000)">
                                    <polygon id="Rectangle" transform="translate(7.531782, 8.468379) rotate(-135.000000) translate(-7.531782, -8.468379) " points="5.24457134 2.14613563 9.81899175 2.14613563 9.81899175 14.7906215 5.24457134 14.7906215"></polygon>
                                    <path d="M15.7043929,2.01403571 L13.986,0.295678571 C13.5673214,-0.123035714 12.8589286,-0.0934642857 12.4037143,0.36175 L10.7893571,1.97614286 L14.0239643,5.21071429 L15.6383214,3.59635714 C16.0934286,3.14114286 16.1230357,2.43271429 15.7043929,2.01403571 Z" id="Path"></path>
                                    <polygon id="Path" points="1.14360714 11.8304286 0 16 4.16917857 14.8563929"></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

IconEdit.propTypes = {
  color: PropTypes.string,
  activeColor: PropTypes.string,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

IconEdit.defaultProps = {
  activeColor: config.colors.rbYellow,
  color: config.colors.rbWhite,
  isActive: false,
  size: 16,
};

export default IconEdit;