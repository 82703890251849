import React, { Component } from 'react';
import FileContent from './FileContent';

class File extends Component {
    render() {
        const props = this.props;
        return (
            <FileContent  {...props}  />
        );
    }
}

export default File;