import React, { Component } from "react";
import { connect } from 'react-redux';
import GridRow from "../../master-components/Grid/GridRow";
import GridCol from "../../master-components/Grid/GridCol";
import InputLabel from "../../master-components/InputLabel/InputLabel";
import Button from '../../master-components/Button/Button';
import moment from 'moment';
import config from '../../config/config';
import IconEdit from '../../master-components/Icons/IconEdit';
import { showModal } from '../../master-components/Modal/ModalActions';
import UserEdit from './Modals/UserEdit';
import GridHeader from "../../master-components/GridHeader/GridHeader";
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import UserAPI from './UserAPI';
import './User.scss';

class UserContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  // getUsers = (id) => {
  //   UserAPI.getUsers(id).then((res => {
  //         const users = res.data.data;
  //         this.setState({usersFromAPI : users });
  //     }));
  // }

  clickEditUser = (user) => {
    const {updateGridUser} = this.props;
    const dispatch = this.props.dispatch;
    dispatch(showModal(UserEdit, 'idUserEdit', {user: user, updateGridUser : updateGridUser}));
  }

  reinvite = (userId, email) => {
    const dispatch = this.props.dispatch;
    dispatch(spinner(true));
    UserAPI.reinviteAdmin(userId)
      .then(res => {
        dispatch(spinner(false));
        alert(`Invite resent to ${email}`);
      })
      .catch(err => {
        dispatch(spinner(false));
        const msg = (err && err.data && err.data.message) || "Something wen't wrong";
        alert(msg);
      });;
  }

  render() {
    const props = this.props;
    const userHeaderFields = [
      { name: 'User ID', className : 'col-1'},
      { name: 'User Name', className : 'col-3' },
      { name: 'Email', className : 'col-3' },
      { name: 'Member Type', className : 'col-1' },
      { name: 'All Locations', className : 'col-1' },
      { name: 'Created On', className : 'col-1' },
      { name: 'Last Login', className : 'col-1' },
      { name: '', className : 'col-1' }
    ];

    const userHeader = userHeaderFields.map((c, i) => (
       <GridHeader {...c} key={i} />
    ));

    const userDetails = props.userFromAPI.map((c, i) => (
     <div className='grid-text-detail grid-row-detail  d-flex grid-bottom-border clickable pl-2' key={i} >
        <GridCol className='align-self-center col-1'>{c.id}</GridCol>
        <GridCol className='align-self-center col-3'>{c.contactName}</GridCol>
        <GridCol className='align-self-center col-3'>{c.email}</GridCol>
        <GridCol className='align-self-center col-1'>{props.memberType}</GridCol>
        <GridCol className='align-self-center col-1'>{c.allLocations ? 'Yes' : 'No'}</GridCol>
        <GridCol className='align-self-center col-1 ml-1'>{c.createTime !== null ? moment(c.createTime).format('MM-DD-YY') : c.createTime }</GridCol>
        <GridCol className='align-self-center col-1 ml-1'>{c.lastLoginDatetime !== null ? moment(c.lastLoginDatetime).format('MM-DD-YY') : c.lastLoginDatetime }</GridCol>
        <GridCol className='align-self-center col-1'>
            <span className='pencil-border mx-3' onClick={this.clickEditUser.bind(this, c)}><IconEdit color={config.colors.rbDarkBlueOpaque} size={16} /></span>
            {!c.isActivated && <Button onClick={this.reinvite.bind(this, c.id, c.email)} className='btn-rb-green clickable' size='sm' text='Reinvite' />}
        </GridCol>
      </div>
    ));

    return (
      <div className='container-fluid p-0 mt-5'>
        <InputLabel className='pl-4'>User List ({props.userFromAPI && props.userFromAPI.length})</InputLabel>
        <GridRow className='m-0'>{userHeader}</GridRow>
        {props.userFromAPI !== undefined ?  userDetails : null}
      </div>
    );
  }
}

export default connect()(UserContent);
