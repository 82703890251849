import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import  DashboadHeader from '../Dashboard/DashboardHeader'
import DashboardBody from './DashboardBody';
import setHeaderTitle from './DashboardActions';
import GridRow from '../../master-components/Grid/GridRow';
class DashboardContent extends Component {

    componentDidMount = () => {
        const dispatch = this.props.dispatch;
        dispatch(setHeaderTitle('Merchant'));
    }

    render() {
        const props = this.props;
        return (
            <Fragment>
                <DashboadHeader  history={this.props.history} {...props} />
                <GridRow className='dashboard-subeader-space'>
                    &nbsp;
                </GridRow>
                <DashboardBody  history={this.props.history} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title: state.dashboard.title
    }
}

export default connect(mapStateToProps)(DashboardContent);
