import React, { Component } from 'react';
import MerchantContent from './MerchantContent';

class Merchant extends Component {
    render() {
        return (
            <MerchantContent  history={this.props.history} />
        );
    }
}

export default Merchant;