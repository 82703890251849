import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import GridRow from "../../../master-components/Grid/GridRow";
import GridCol from "../../../master-components/Grid/GridCol";
import InputLabel from "../../../master-components/InputLabel/InputLabel";
import GridHeader from "../../../master-components/GridHeader/GridHeader";
import NoDataFound from '../../../master-components/NoDataFound/NoDataFound';

class SiblingContent extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    goToEditChildren = (merchant) => {
        $("html, body").animate({ scrollTop: 0 });
        this.props.editMerchantChildren(merchant);
    }

    render() {
        const props = this.props;
        const childrenHeaderFields = [
            { name: 'Merchant ID', className : 'col-2' },
            { name: 'Merchant Name', className : 'col-3' },
            { name: 'Created On', className : 'col-2' },
            { name: 'Last Loaded', className : 'col-2' },
            { name: '', className : 'col-3' }
        ];
      
          const childrenHeader =  childrenHeaderFields.map((c, i) => (
             <GridHeader {...c} key={i} />
          ));
    
          const childrenDetails = props.familyFromAPI !== undefined ? props.familyFromAPI.map((c, i) => (
           <div className='grid-text-detail grid-row-detail  d-flex grid-bottom-border clickable pl-3' key={i} onClick={this.goToEditChildren.bind(this, c, props.familyFromAPI)}>
              <GridCol className='align-self-center col-2'>{c.id}</GridCol>
              <GridCol className='align-self-center col-3'>{c.nickname}</GridCol>            
              <GridCol className='align-self-center col-2'>{c.createTime !== null ? moment(c.createTime).format('MM-DD-YY') : c.createTime }</GridCol>
              <GridCol className='align-self-center col-2'>{c.lastLoaded !== null ? moment(c.lastLoaded).format('MM-DD-YY') : c.lastLoaded }</GridCol>
              <GridCol className='align-self-center col-3'></GridCol>
            </div> 
         )) :  <NoDataFound/>;  

        return (
            <div className='container-fluid p-0 mt-5'>
                <InputLabel className='pl-4'>Sibling List</InputLabel>
                <GridRow className='m-0'>{childrenHeader}</GridRow>
                { childrenDetails }
            </div>
        );
    }
}

export default SiblingContent;