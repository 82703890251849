import axios from 'axios';

const getUsers = (id) => {
    return axios.get(`/merchant/${id}/users`);
}

const updateUser = (id, params) => {
    return axios.patch(`/user/${id}`, params);
};

const resetUserPassword = (id, params) => {
    return axios.patch(`/user/${id}/reset-password`, params);
};

const reinviteAdmin = (id) => {
    return axios.post(`/user/${id}/reinvite-admin`);
};


export default {
    getUsers,
    updateUser,
    resetUserPassword,
    reinviteAdmin,
}
