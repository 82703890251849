import { createStore } from 'redux';
import { combineReducers } from 'redux';
import spinner from '../master-components/Spinner/SpinnerReducer';
import modals from '../master-components/Modal/ModalReducer';
import resizeListener from '../master-components/ResizeListener/ResizeListenerReducer';
import dashboard from '../views/Dashboard/DashboardReducer';
import merchant from '../views/Merchant/MerchantReducer';


const reducer = combineReducers({
  // Add Reducers here
  spinner,
  modals,
  dashboard,
  merchant,
  resizeListener
});

export default createStore(reducer);