import React, { Component } from 'react';
import GridCol from "../../master-components/Grid/GridCol"
class NoDataFound extends Component {
    render() {
        return (
            <div className='row grid-text-detail grid-row-detail  d-flex grid-bottom-border'>
                    <GridCol className='align-self-center text-center' >No Data Found</GridCol>
            </div> 
        );
    }
}

export default NoDataFound;