import React, { Component, Fragment } from 'react';
import config from '../../config/config';
import GridRow from '../../master-components/Grid/GridRow';
import GridCol from '../../master-components/Grid/GridCol';
import Button from '../../master-components/Button/Button';
import IconLogOut from '../../master-components/Icons/IconLogOut';
import './Dashboard.scss';
// import removeToken from '../../config/tokenAPI';
import back from '../../assets/images/back.png';
import MerchantAPI from '../Merchant/MerchantAPI';
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import { withMsal } from '@azure/msal-react';
//import { showModal } from '../../master-components/Modal/ModalActions';

class DashboardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    downloadTemplate = () => {
      const excelEl = document.createElement('a');
      excelEl.href = 'https://s3.amazonaws.com/buyersedgepayments.com/resources/templates/Merchants-template.xlsx';
      excelEl.click();
    }

    selectTemplate = (e) => {
      e.preventDefault();
      const { inputFile } = this.refs;
      inputFile.value = '';
      inputFile.click();
    }

    uploadTemplate = (inputFile) => {
      const { dispatch } = this.props;

      const excelTypes = ['csv', 'xls', 'xlsx'];
      const file = inputFile.currentTarget.files[0];
      const extension = file.name.match(/[0-9a-z]+$/i)[0];
      const isTypeOk = excelTypes.indexOf(extension) !== -1;
      if (isTypeOk) {

        dispatch(spinner(true));
        MerchantAPI.importMerchants(file).then(({data}) => {
          dispatch(spinner(false));
          if (this.props.history.location.pathname === '/dashboard') {
            window.location.reload();
          } else {
            this.props.history.push('/dashboard');
          }
        }).catch(err => {
          dispatch(spinner(false));
          let msg = `Something wen't wrong`;
          if (err) {
            if (err.data && err.data.message) {
              msg = err.data.message;
            }
            //dispatch(showModal(AlertModal, {title: msg}));
            alert(msg);
          }
        });

      }
    }

    goBack = () => {
        this.props.history.push('/dashboard');
    };

    logOut = () => {
        localStorage.clear();
        // removeToken();
        const instance = this.props.msalContext.instance;
        instance.logoutRedirect({
            account: instance.getAccountByHomeId(),
            postLogoutRedirectUri: '/',
            mainWindowRedirectUri: '/'
        });
        this.props.history.push('/');
    }

    render() {
        const props = this.props;
        const isAuthenticated = props.msalContext.accounts.length > 0;
        // const msalInstance = props.msalContext.instance;
        // console.log('this.props.msalContext', this.props.msalContext);
        return (
            <div className={'dashboard-header-container grid-bottom-border ' + ( props.history.location.pathname === '/dashboard' ? 'dashboard-header-fixed' : '')}>
                <GridRow className='pt-4 pr-5 pl-4'>
                    <GridCol>
                      { props.showBack ?
                        <GridCol><span className='clickable' onClick={this.goBack}><img src={back} alt='back' /> &nbsp; Back</span></GridCol>
                      : null }
                    </GridCol>
                    <GridCol className='text-center dashboard-header-text'>
                        { props.title }
                    </GridCol>
                    <GridCol className='col-6 text-right'>
                        <input type='file' id='file' onChange={this.uploadTemplate} ref='inputFile' style={{display: 'none'}} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx, application/vnd.oasis.opendocument.spreadsheet" />
                            <Fragment>
                                <Button className='p-1 mr-2' size='md' onClick={this.selectTemplate} variant='blue' text='UPLOAD NEW' style={{height:'40px'}} />
                                <Button className='p-1 mr-2' size='md' onClick={this.downloadTemplate} variant='blue' text='DOWNLOAD TEMPLATE' style={{height:'40px'}} />
                            </Fragment>
                        { isAuthenticated ?
                            <span className='dashboard-header-text mr-2'>{ props.msalContext.accounts[0].username }</span>
                        : null }
                        <span className='clickable text-uppercase dashboard-header-logout' onClick={this.logOut}>
                            <IconLogOut  color={config.colors.rbDarkBlueOpaque} size={16} /> &nbsp;
                            logout
                        </span>
                    </GridCol>
                </GridRow>
            </div>
        );
    }
}

export default withMsal(DashboardHeader);
