import React, { Component } from 'react';
import './CheckBox.scss';
import PropTypes from 'prop-types';

class Checkbox extends Component {
    onClick = (event) => {
        if (!this.props.readOnly) {
            this.props.onChange(this.props.name, event);
        }
    }
    render() {
        const props = this.props;
        const { className, text } = props;
        return (
            <label className={'checkbox-container' + (className ? ` ${className}` : '')}>{text}
                <input
                    type='checkbox'
                    checked={props.value}
                    disabled={props.disabled}
                    onChange={this.onClick}
                    readOnly={props.readOnly}
                />
                <span className='checkmark'></span>
            </label>
        );
    }
};


Checkbox.propTypes = {
    title: PropTypes.any,
    name: PropTypes.any.isRequired,
    value: PropTypes.any,
    text: PropTypes.string,
    className: PropTypes.string,
    grayText: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
}

Checkbox.defaultProps = {
    title: ''
}

export default Checkbox;