import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as $ from 'jquery';
import { destroyModal, saveParent } from './ModalActions';
import './Modal.scss';
import { isMobile } from 'react-device-detect';

class Modal extends Component {
  componentDidMount = () => {
    this.canGoBack = true;
    const {id, onShow, onCreate, dispatch} = this.props;
    setTimeout(() => {
      $('#'+id).addClass('show');
      $('body').addClass("modal-open");
      $('html').addClass("modal-open");
      if (onCreate) {
        onCreate();
      }
      setTimeout(() => {
        if (onShow) {
          onShow();
        }  
      }, 300);
    });
    dispatch(saveParent(id, this));
  };
  close = () => {
    const { id, onHide, onDestroy, dispatch } = this.props;
    $('#'+id).removeClass("show");
    $('#'+id).addClass("hide");
    $('#'+id+'-backdrop').removeClass("show");
    if (onHide) {
      onHide();
    }
    setTimeout(() => {
      if (this.props.modals.length > 1) {
        $('body').addClass("modal-open");
        $('html').addClass("modal-open");
      } else {
        $('body').removeClass("modal-open");
        $('html').removeClass("modal-open");
      }
      if (onDestroy) {
        onDestroy();
      }
      setTimeout(()=>{
        dispatch(destroyModal(id));
      }, 100)
    }, 300);
  }
  closeBackdrop = (e) => {
    const { backdrop } = this.props;
    if (backdrop) {
      this.close();
    }
  }
  render() {
    const {id, className, size, children, animation, centered, fullHeight} = this.props;
    const styleFullHeight = {
      minHeight: this.props.vh+'px'
    }
    const childrensWithProps = React.Children.map(children, child => {
      return React.cloneElement(child, {
        parent: this
      });
    });

    return (
      <Fragment>
        <div id={id + '-backdrop'} style={{zIndex: '1000'}} className='modal-backdrop fade show'></div>
        <div style={{display: 'block', zIndex: '1000'}} className={'modal rb-modal' + (animation ? ` ${animation}` : '') + (className ? ` ${className}` : '') } id={id} tabIndex="-1">
          <div onClick={this.closeBackdrop} className='rb-backdrop'></div>
          <div style={centered ? styleFullHeight : null } className={'modal-dialog' + (centered ? ' rb-modal-dialog-centered ' : ' ') + 'modal-' + size + (isMobile ? ' mobile' : '')}>
            <div className='modal-content' style={fullHeight ? styleFullHeight : null}>
              {childrensWithProps}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  animation: PropTypes.string,
  centered: PropTypes.bool,
  fullHeight: PropTypes.bool,
  backdrop: PropTypes.bool,
  keyboard: PropTypes.bool,
  onHide: PropTypes.func,
  onDestroy: PropTypes.func,
  onCreate: PropTypes.func,
  onShow: PropTypes.func
};

Modal.defaultProps = {
  size: isMobile ? '100' : 'md',
  animation: isMobile ? 'fade fade-down' : 'fade',
  className: '',
  centered: false,
  fullHeight: false,
  backdrop: true,
  keyboard: true
};

const mapStateToProps = (state) => {
  return {
    modals: state.modals.modals,
    vh: state.resizeListener.height
  };
};

export default withRouter(connect(mapStateToProps)(Modal));