import React from 'react';
import PropTypes from 'prop-types';

const GridCol = props => (
  <div style={{maxWidth: props.maxWidth, ...props.style}} id={props.id} className={'col' + (props.breakpoint ? '-' + props.breakpoint : '') + (props.size ? '-' + props.size : '') + (props.className ? ' ' + props.className : '') + (props.align ? ' align-self-' + props.align : '') + (props.justify ? ' justify-content-' + props.justify : '')} onMouseLeave={props.onMouseLeave} onMouseEnter={props.onMouseEnter} onClick={props.onClick} tabIndex={props.tabIndex} onBlur={props.onBlur} onFocus={props.onFocus} onMouseDown={props.onMouseDown}>
    {props.children}
  </div>
);

GridCol.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
  maxWidth: PropTypes.string,
  breakpoint: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default GridCol;