import React, { Component } from 'react';
import ChildrenContent from './ChildrenContent';


class Children extends Component {
    render() {
        const props = this.props;
        return (
            <ChildrenContent {...props} />
        );
    }
}

export default Children;