import React, { Component } from 'react';
import UserContent from './UserContent';

class User extends Component {
    render() {
        const props = this.props;
        return (
            <UserContent {...props} />
        );
    }
}

export default User;