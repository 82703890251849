import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';

class ModalContent extends Component {
  render() {
    const modalsMap = this.props.modals.map((modal, i)=>
      <modal.Component key={modal.id} {...modal.props} modals={this.props.modals}></modal.Component>
    );
    return (
      <Fragment>
        {modalsMap}
      </Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    modals: state.modals.modals
  }
}

export default connect(mapStateToProps)(ModalContent);