import React, { Component } from 'react';
import DashboardContent from '../Dashboard/DashboardContent'

class Dashboard extends Component {
    render() {
        return (
            <div>
                <DashboardContent  history={this.props.history} />
            </div>
        );
    }
}

export default Dashboard;