import React from 'react';
import ReactDOM from 'react-dom';
import './App/index.css';
import App from './App/App';
import * as serviceWorker from './App/serviceWorker';
import './config/tokenAPI';
import './include/boostrap';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './App/store';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/msAuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <MsalProvider instance={msalInstance}><App /></MsalProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
