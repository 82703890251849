import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Spinner extends Component {

    render() {
        const blueDots = (
            <Fragment>
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </Fragment>
        )
        if (this.props.spinner) {
            return (
                <div className="mask">
                    <div className="spinner">
                        {blueDots}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="mask hide">
                    <div className="spinner">                            
                    </div>
                </div>
            );
        }
    }
}

Spinner.propTypes = {
    spinner: PropTypes.bool
};
const mapStateToProps = (state) => {
    return {
        spinner: state.spinner.spinner
    }
}

export default connect(mapStateToProps)(Spinner);