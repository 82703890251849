import React, { Component } from 'react';
import moment from 'moment';
import GridRow from "../../master-components/Grid/GridRow";
import GridCol from "../../master-components/Grid/GridCol";
import InputLabel from "../../master-components/InputLabel/InputLabel";
import GridHeader from "../../master-components/GridHeader/GridHeader";

class ParentContent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps = (nextProps) => {
        const {objEditChildren } = nextProps;
        this.setState({
            parent : objEditChildren.parent
        });
    }

    render() {
        const props = this.props; 
        const parentHeaderFields = [
            { name: 'Merchant ID', className : 'col-2' },
            { name: 'Merchant Name', className : 'col-3' },
            { name: 'Created On', className : 'col-2' },
            { name: 'Last Loaded', className : 'col-2' },
            { name: '', className : 'col-3' }
        ];
      
        const parentHeader = parentHeaderFields.map((c, i) => (
            <GridHeader {...c} key={i} />
        ));
      
        const {id, nickname, createTime, lastLoaded} = props.familyFromAPI[0].parent === null ?  props.familyFromAPI[0] : props.familyFromAPI[0].parent;
        return (
            <div className='container-fluid p-0 mt-5'>
                <InputLabel className='pl-4'>Parent</InputLabel>
                <GridRow className='m-0'>{parentHeader}</GridRow>
                <div className='grid-text-detail grid-row-detail  d-flex grid-bottom-border clickable pl-3'>
                    <GridCol className='align-self-center col-2'>{id}</GridCol>
                    <GridCol className='align-self-center col-3'>{nickname}</GridCol>            
                    <GridCol className='align-self-center col-2'>{createTime !== null ? moment(createTime).format('MM-DD-YY') : createTime }</GridCol>
                    <GridCol className='align-self-center col-2'>{lastLoaded !== null ? moment(lastLoaded).format('MM-DD-YY') : lastLoaded }</GridCol>
                    <GridCol className='align-self-center col-4'></GridCol>
                </div> 
            </div>
        );
    }
}

export default ParentContent;