import React from 'react';
import PropTypes from 'prop-types'; 

const InputLabel = props => (
  <label className={props.title ? `${props.className}` : ('text-grey text-size-12 mb-2' +(props.className ? ` ${props.className}` : ''))}>{props.text || props.children}</label>
);

InputLabel.propTypes = {
  text: PropTypes.string,  
  className: PropTypes.string,
  title: PropTypes.bool
}

export default InputLabel;