import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config';
import IconClose from '../Icons/IconClose';
import { isMobile } from 'react-device-detect';

class ModalHeader extends Component {
  close = () => {
    this.props.parent.close();
  }
  render() {
    const props = this.props;
    return (
      <div className={'modal-header text-center align-self-center rb-modal-header' + (props.className ? ` ${props.className}` : '') + (isMobile ? ' mobile' : '') + (props.noBorder ? ' border-0' : '') + (props.blueHeader ? ' blue-header' : '')}>
        <div>{props.children}</div>
        {
          props.closeButton ?
            <button type="button" className="close rb-close" data-dismiss="modal">
              <span onClick={this.close}><IconClose color={config.colors.rbGrey} fontSize='24px' /></span>
            </button>
          : null
        }
      </div>
    )
  }
}

ModalHeader.propTypes = {
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  noBorder: PropTypes.bool,
  blueHeader: PropTypes.bool
}

ModalHeader.defaultProps = {
  closeButton: true,
}

export default ModalHeader;