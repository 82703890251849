import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import $ from 'jquery';
import DashboardHeader from '../Dashboard/DashboardHeader';
import MerchantEdit from './MerchantEdit';
import User from '../User/User';
import Distributor from '../Distributor/Distributor';
import {setHeaderTitle, showHeaderBack} from '../Dashboard/DashboardActions';
import Children from '../Children/Children';
import File from '../File/File';
import Parent from '../Parent/Parent';
import UserAPI from '../User/UserAPI';
import ChildrenAPI from '../Children/ChildrenAPI';
import Sibling from '../Parent/Sibling/Sibling';
import { spinner } from '../../master-components/Spinner/SpinnerActions';
import MerchantAPI from './MerchantAPI';

class MerchantDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantFromAPI: [],
            userFromAPI: [],
            objEditChildren: '',
            isParent : 0,
            isSibling : 0,
            hash: Math.random(),
        }
    }

    componentDidMount = () => {
        $("html, body").animate({ scrollTop: 0 });
        const dispatch = this.props.dispatch;
        const merchantId = this.props.match.params.merchantId;
        dispatch(setHeaderTitle('Merchant Details'));
        dispatch(showHeaderBack(true));
        this.getMerchant(merchantId);
        this.getUsers(merchantId);
        this.getFamily(merchantId);
    }

    editMerchantChildren = (data) => {
        this.setState({ objEditChildren : data });
        ChildrenAPI.getFamily(data.id).then((res => {
            const family = res.data.data;
            if(family[0].parentId === null){
                this.setState({ isParent : 1 });
            } else {
                if(family.length > 0 ){
                    this.setState({ isSibling : 1 })
                } else {
                    this.setState({ isSibling : 0 })
                }
                this.setState({ familyFromAPI : family });
            }
      }));
    }

    updateGridUser = () => {
        const merchantId = this.props.match.params.merchantId;
        this.getUsers(merchantId);
    }

    getUsers = (merchantId) => {
        UserAPI.getUsers(merchantId).then((res => {
              const users = res.data.data.filter(u => u.type !== 'My Distributor Portal');
              this.setState({userFromAPI : users });
        }));
    }

    getFamily = (merchantId) => {
        ChildrenAPI.getFamily(merchantId).then((res => {
            const dataFamily = res.data.data;
            this.setState({ familyFromAPI : dataFamily });
            if(dataFamily.length > 0 && dataFamily[0].parentId !== null) {
                if(dataFamily[0].parentId !== parseInt(merchantId)){
                    this.setState({ isSibling : 1 });
                } else{
                    this.setState({ isSibling : 0 });
                }
            } else {
                if(dataFamily[0].parentId === null) {
                    this.setState({ isParent : 1 });
                } else {
                    this.setState({ isSibling : 0,  isParent : 0 });
                }
            }
        }));
    }

    syncMechantLocations = (id) => {
        const dispatch = this.props.dispatch;

        dispatch(spinner(true));
        MerchantAPI.syncMechantLocations(parseInt(id))
            .then(({data}) => {
                dispatch(spinner(false));
                this.getFamily(id);
            }).catch(error => {
                dispatch(spinner(false));
                if (error && error.status) {
                    let errorMessage = error.data.data.message;
                    alert(errorMessage);
                }
            });
    }

    syncMechantDistributors = (id) => {
        const dispatch = this.props.dispatch;

        dispatch(spinner(true));
        MerchantAPI.syncMechantDistributors(parseInt(id))
            .then(({data}) => {
                dispatch(spinner(false));
                this.setState({ hash : Math.random() });
            }).catch(error => {
                dispatch(spinner(false));
                if (error.status) {
                    let errorMessage = error.data.data.message;
                    alert(errorMessage);
                }
            });
    }

    getMerchant = (merchantId) => {
        MerchantAPI.getMerchantById(merchantId).then((res => {
              const merchant = res.data.data;
              this.setState({merchantFromAPI : merchant});
        }));
    }

    render() {
        const props = this.props;

        return (
            <Fragment>
                <DashboardHeader {...props}  />
                <div className='dashboard-body-container mt-4 mb-4'>
                    <MerchantEdit {...props} syncMechantLocations={this.syncMechantLocations} syncMechantDistributors={this.syncMechantDistributors} objEditChildren={this.state.objEditChildren} dataFromAPI = {this.state.dataFromAPI} />
                    <User {...props} updateGridUser = {this.updateGridUser} userFromAPI = {this.state.userFromAPI} memberType={this.state.merchantFromAPI.memberType || this.state.merchantFromAPI.parentMemberType} />
                    <Distributor {...props} hash={this.state.hash} />
                    {(this.state.isSibling === 1 || this.state.isParent === 1) ? <Parent {...props} familyFromAPI = {this.state.familyFromAPI} objEditChildren={this.state.objEditChildren}  /> : null }
                    { this.state.isSibling === 1  ?  <Sibling  editMerchantChildren={this.editMerchantChildren} familyFromAPI = {this.state.familyFromAPI} /> : (
                      this.state.isParent !== 1 ?
                     <Children {...props}  editMerchantChildren={this.editMerchantChildren} familyFromAPI = {this.state.familyFromAPI}  /> : null)
                    }
                    <File {...props} fileFromAPI = {this.state.fileFromAPI} />
              </div>
            </Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        title: state.dashboard.title,
        showBack : state.dashboard.showBack
    };
}

export default connect(mapStateToProps)(MerchantDetails);
