import React, { Component } from 'react';
import Merchant from '../Merchant/Merchant';

class DashboardBody extends Component {
    render() {
        return (
            <div className='dashboard-body-container mt-4 mb-4' style={{paddingTop:'125px'}}>
               <Merchant  history={this.props.history}/>
            </div>
        );
    }
}

export default DashboardBody;