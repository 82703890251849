import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import GridRow from "../../master-components/Grid/GridRow";
import GridCol from "../../master-components/Grid/GridCol";
import InputLabel from "../../master-components/InputLabel/InputLabel";
import GridHeader from "../../master-components/GridHeader/GridHeader";
import FileAPI from '../File/FileAPI';
import './File.scss';
import NoDataFound from '../../master-components/NoDataFound/NoDataFound';

class FileContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            fileFromAPI: []
        };
    }

    componentDidMount = () => {
        const merchantId = this.props.match.params.merchantId;
        this.getS3Files(merchantId);
    }

    getS3Files = (merchantId) => {
        FileAPI.getS3Files(merchantId).then((res => {
            const files = res.data.data;
            this.setState({fileFromAPI : files });
        }));
    }

    render() {
        const fileHeaderFields = [
            { name: 'S3 Links', className : 'col-2'},
            { name: 'Uploaded On', className : 'col-3' },
            { name: 'Data Capture Type', className : 'col-2'},
            { name: '', className : 'col-5'}
        ];

          const fileHeader = fileHeaderFields.map((c, i) => (
                <GridHeader {...c} key={i} />
          ));

          const fileDetails =  this.state.fileFromAPI.map((c, i) => (
           <div className='grid-text-detail grid-row-detail  d-flex grid-bottom-border clickable pl-3' key={i}>
              <GridCol className='align-self-center col-2 link-file'  onClick={() => window.open(c.file_url, '_blank')}>Link</GridCol>
              <GridCol className='align-self-center col-3'>{c.create_time !== null ? moment(c.create_time).format('MM-DD-YY') : c.create_time }</GridCol>
              <GridCol className='align-self-center col-2 text-capitalize'>{c.submission_type}</GridCol>
              <GridCol className='align-self-center col-5'></GridCol>
            </div>
          ));

        return (
            <div className='container-fluid p-0 mt-5'>
                <InputLabel className='pl-4'>Files ({this.state.fileFromAPI && this.state.fileFromAPI.length})</InputLabel>
                <GridRow className='m-0'>{fileHeader}</GridRow>
                {fileDetails.length > 0 ? fileDetails :  <NoDataFound /> }
            </div>
        );
    }
}

export default connect()(FileContent);

//export default FileContent;
