import React, { Component } from 'react';
import './Login.scss';
import LoginContent from './LoginContent';
class Login extends Component {

       render() {
        //const url = '/login/';
        //const token  = this.props.location.pathname.substring(url.length);
        return (            
            <div className='container-login'>
                <LoginContent history={this.props.history} />
            </div>
        )
    }
}

export default Login;